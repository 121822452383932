/**
 * @description - Routes variable for different platforms.
 * Contains list of RouteItem type objects that was passed to
 * renderRoutes method for Switch component
 */

// ================================================================================================================== //
// ===================================================== MODULES ==================================================== //
// ================================================================================================================== //

// Lazy loading is the technique of rendering only-needed or
// critical user interface items first, then quietly unrolling the non-critical
// items later. It is now fully integrated into core react library itself.
import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
// Paths
import { PATH_APP_DOCS, PATH_APP_AUTH, PATH_APP_MAIN } from '../../routes';
// Types
import { TypeRouteItem } from '../../@types';
// Components
import { Loadable, AuthGuard, LogoBisflowGreen } from '../../components';
// import PlatformAccessGuard from '../../components/guards/PlatformAccessGuard';
// Layouts
import {LogoOnlyLayout, MainLayout} from '../../layouts';

// ================================================================================================================== //
// ======================================================= PAGES ==================================================== //
// ================================================================================================================== //

// Authentication Pages
const PermissionDeniedPage = Loadable(lazy(() => import('../access/PagePermissionDenied')));
// const MyContractPage = Loadable(lazy(() => import('./MyContractPage')));

// ================================================================================================================== //
// ===================================================== ROUTES ===================================================== //
// ================================================================================================================== //

/**
 * @description - Bisflow routes list, contains list of RouteItem type
 * objects
 */
const docsRoutes: TypeRouteItem = {
  path: '/',
  element: (
    <AuthGuard path={PATH_APP_AUTH.rootFull}>
      <MainLayout />
    </AuthGuard>
  ),
  children: [
    // Redirect
    { element: <Navigate to={'/contract/testId'} replace />, index: true },
    // {
    //   path: 'contract/:id',
    //   element: <MyContractPage />
    // },
  ]
};

export default docsRoutes;

/**
 * @description - Routes variable for different platforms.
 * Contains list of RouteItem type objects that was passed to
 * renderRoutes method for Switch component
 */

// ================================================================================================================== //
// ===================================================== MODULES ==================================================== //
// ================================================================================================================== //

// Lazy loading is the technique of rendering only-needed or
// critical user interface items first, then quietly unrolling the non-critical
// items later. It is now fully integrated into core react library itself.
import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
// Paths
import { PATH_APP_DOCS, PATH_APP_AUTH } from './index';
// Types
import { TypeRouteItem } from '../@types';
// Components
import { Loadable, AuthGuard } from '../components';
// Providers
import { FirebaseDocumentsProvider } from '../contexts';
// Layouts
import { MainLayout } from '../layouts';
// Navigation configuration
import { docsNavBarConfig } from './index';

// ================================================================================================================== //
// ======================================================= PAGES ==================================================== //
// ================================================================================================================== //

// Authentication Pages
const MaintenancePage = Loadable(lazy(() => import('../pages/maintenance/index')));
const PagePermissionDenied = Loadable(lazy(() => import('../pages/access/PagePermissionDenied')));
const MyContractPage = Loadable(lazy(() => import('../pages/contract/MyContract')));

// ================================================================================================================== //
// ===================================================== ROUTES ===================================================== //
// ================================================================================================================== //

/**
 * @description - Bisflow routes list, contains list of RouteItem type
 * objects
 */
const docsRoutes: TypeRouteItem = {
  path: '/',
  element: (
    <AuthGuard path={`${PATH_APP_AUTH.rootFull}/login-password`}>
      <MainLayout />
    </AuthGuard>
  ),
  children: [
    { element: <Navigate to={'/contract'} replace />, index: true },
    // Dashboard
    {
      path: '/contract',
      element: <div>No any contract found, please use the link from the email that you received</div>
    },
    {
      path: '/contract/:id',
      element: <MyContractPage />
    },
  ]
};

export default docsRoutes;

/**
 * @description - The guest guarding wrapper component. allowing to get access without authentication. If the user
 * logged in, then user will be redirected to the PATH_ROOTS_APP path.
 */

// ================================================================================================================== //
// ===================================================== MODULES ==================================================== //
// ================================================================================================================== //
// React
import { ReactNode } from 'react';
import {Navigate, useParams} from 'react-router-dom';
// hooks
import { useAuth } from '../../hooks';

// ================================================================================================================== //
// ====================================================== LOGIC ===================================================== //
// ================================================================================================================== //

/**
 * @description - The wrapper component's property interface.
 */
type GuestGuardProps = {
  children: ReactNode;
  path: string;
};

/**
 * @description - The guest guarding wrapper component.
 * @param children
 * @param path
 * @constructor
 */
const GuestGuard = ({ children, path }: GuestGuardProps) => {
  const { isAuthenticated } = useAuth();
  const {id} = useParams();
  // console.log(id);
  let finalPath = path;
  if (id) {
    finalPath = `https://contract.bisflow.io/contract/${id}`;
  }
  if (isAuthenticated) {
    window.location.href = finalPath;
  }

  return <>{children}</>;
}

export default GuestGuard;

/**
 * @description - Routes variable for different platforms. Contains list of RouteItem type objects that was passed to
 * renderRoutes method for Switch component.
 */

// ================================================================================================================== //
// ===================================================== MODULES ==================================================== //
// ================================================================================================================== //

// Lazy loading is the technique of rendering only-needed or
// critical user interface items first, then quietly unrolling the non-critical
// items later. It is now fully integrated into core react library itself.
import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
// Paths
import { PATH_APP_AUTH, PATH_APP_MAIN } from './index';
// Types
import { TypeRouteItem } from '../@types';
// Components
import { Loadable, GuestGuard } from '../components';

// ================================================================================================================== //
// ======================================================= PAGES ==================================================== //
// ================================================================================================================== //

// Authentication Pages
const LoginPassword = Loadable(lazy(() => import('../pages/auth/LoginPassword')));
const LoginEmailLink = Loadable(lazy(() => import('../pages/maintenance/index')));
const Register = Loadable(lazy(() => import('../pages/maintenance/index')));
const ResetPassword = Loadable(lazy(() => import('../pages/maintenance/index')));
const VerifyCode = Loadable(lazy(() => import('../pages/maintenance/index')));

// ================================================================================================================== //
// ===================================================== ROUTES ===================================================== //
// ================================================================================================================== //

/**
 * @description - Authentication routes list, contains list of RouteItem type objects.
 */
const authRoutes: TypeRouteItem = {
  path: PATH_APP_AUTH.root,
  children: [
    {
      path: PATH_APP_AUTH.loginEmail,
      element: <GuestGuard path={PATH_APP_MAIN.rootFull}>
        <LoginEmailLink />
      </GuestGuard>
    },
    {
      path: PATH_APP_AUTH.login,
      element: <GuestGuard path={PATH_APP_MAIN.rootFull}>
          <LoginPassword />
      </GuestGuard>
    },
    {
      path: 'active',
      element: <GuestGuard path={PATH_APP_MAIN.rootFull}>
        <LoginPassword />
      </GuestGuard>
    },
    {
      path: `${PATH_APP_AUTH.login}/:id`,
      element: <GuestGuard path={PATH_APP_MAIN.rootFull}>
        <LoginPassword />
      </GuestGuard>
    },
    {
      path: PATH_APP_AUTH.register,
      element: (
        <GuestGuard path={PATH_APP_MAIN.rootFull}>
          <Register />
        </GuestGuard>
      )
    },
    {
      path: PATH_APP_AUTH.loginUnprotected,
      element: (
        <GuestGuard path={PATH_APP_MAIN.rootFull}>
          <LoginPassword />
        </GuestGuard>
      )
    },
    {
      path: PATH_APP_AUTH.registerUnprotected,
      element: (
        <GuestGuard path={PATH_APP_MAIN.rootFull}>
          <p>test 4</p>
        </GuestGuard>
      )
    },
    {
      path: PATH_APP_AUTH.resetPassword,
      element: (
        <GuestGuard path={PATH_APP_MAIN.rootFull}>
          <ResetPassword />
        </GuestGuard>
      )
    },
    {
      path: PATH_APP_AUTH.verify,
      element: (
        <GuestGuard path={PATH_APP_MAIN.rootFull}>
          <VerifyCode />
        </GuestGuard>
      )
    },
    { element: <Navigate to={PATH_APP_AUTH.login} />, index: true },
  ]
};

export default authRoutes;
